import { FormControl } from "@mui/material";
import React from "react";
import { useEffect, useState} from "react";
import AsyncSelect from "../AsyncSelect";
import { i18n } from "../../translate/i18n";

const SelectUsers = ({onChange, selectedUsers, disabled, width, outlined, multiple}) => {
    const [internalSelectedUsers, setInternalSelectedUsers] = useState(multiple ? [] : null);

    const handleChange = (event, changedUsers) => {
        setInternalSelectedUsers(changedUsers);
        if (onChange) {
            onChange(changedUsers);
        }
    };

    const renderSelect = () => {
        return (
            <AsyncSelect
                width="100%"
                url="/users"
                disabled={disabled}
                dictKey="users"
                initialValue={selectedUsers}
                multiple={multiple}
                onChange={handleChange}
                value={internalSelectedUsers}
                placeholder="Selecione os atendentes..."
                label={i18n.t("dashboardHome.attendants")}
                disableCloseOnSelect={multiple}
                />
        );
    }

    return (
        outlined ? 
        <FormControl fullWidth={!width} style={{width: width || '100%'}} variant="outlined">
            <AsyncSelect 
                url="/users" placeholder="Selecione os atendentes..." 
                dictKey="users" onChange={handleChange}
                multiple={multiple}
                width="100%"
                label={i18n.t("contactModal.title.attendants")} 
                disabled={disabled}
                disableCloseOnSelect={multiple}
            />
        </FormControl>
        : 
        renderSelect()
    );
};

export default SelectUsers;