import React, { useEffect, useRef } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import * as am5 from "@amcharts/amcharts5";
import * as am5radar from "@amcharts/amcharts5/radar";
import { useTheme } from "@mui/material";

import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const useStyles = makeStyles(theme => ({
    chartContainer: {
        width: "100%",
        height: "450px",
        fontSize: 14
    }
}));

const ChartTickets = ({ data, color }) => {
    const classes = useStyles();
    const chartRef = useRef(null);
    const colorChart = am5.color(color);
    const theme = useTheme();
    useEffect(() => {
        if (chartRef.current) {
            let root = am5.Root.new(chartRef.current);


            let fillColor = colorChart || root.interfaceColors.get("alternativeBackground");


            root.setThemes([
                am5themes_Animated.new(root)
            ]);

            let chart = root.container.children.push(am5radar.RadarChart.new(root, {
                panX: false,
                panY: false,
                wheelX: "panX",
                wheelY: "zoomX",
                innerRadius: am5.percent(20),
                startAngle: -90,
                endAngle: 180
            }));

            // Create Y-axis
            let cursor = chart.set("cursor", am5radar.RadarCursor.new(root, {
                behavior: "zoomX"
            }));

            cursor.lineY.set("visible", false);

   
            let xRenderer = am5radar.AxisRendererCircular.new(root, {
                //minGridDistance: 50
            });

            xRenderer.labels.template.setAll({
                radius: 10
            });

            xRenderer.grid.template.setAll({
                forceHidden: true
            });

            let xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
                renderer: xRenderer,
                min: 0,
                max: 100,
                strictMinMax: true,
                numberFormat: "#'%'",
                invert: theme.mode === 'light'? 0:1,
                tooltip: am5.Tooltip.new(root, {})
            }));


            let yRenderer = am5radar.AxisRendererRadial.new(root, {
                minGridDistance: 20,
                fill: theme.palette.text.main,
            });

            yRenderer.labels.template.setAll({
                centerX: am5.p100,
                fontWeight: "500",
                fontSize: 18,
                fill: theme.palette.text.main,
                templateField: "columnSettings"
            });

            yRenderer.grid.template.setAll({
                forceHidden: true
            });

            let yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
                categoryField: "category",
                renderer: yRenderer
            }));

            yAxis.data.setAll(data);

            let series1 = chart.series.push(am5radar.RadarColumnSeries.new(root, {
                xAxis: xAxis,
                yAxis: yAxis,
                clustered: false,
                valueXField: "full",
                categoryYField: "category",
                fill:fillColor,
            }));

            series1.columns.template.setAll({
                width: am5.p100,
                fillOpacity: 0.08,
                strokeOpacity: 0,
                cornerRadius: 20
            });

            series1.data.setAll(data);

            let series2 = chart.series.push(am5radar.RadarColumnSeries.new(root, {
                xAxis: xAxis,
                yAxis: yAxis,
                clustered: false,
                valueXField: "value",
                categoryYField: "category",
                fill: theme.palette.text.main,

            }));

            series2.columns.template.setAll({
                width: am5.p100,
                strokeOpacity: 0,
                tooltipText: "{category}: {valueX}%",
                cornerRadius: 20,
                templateField: "columnSettings",
                fill: theme.palette.primary.main,
               
            })

            series2.data.setAll(data);

            series1.appear(1000);
            series2.appear(1000);
            chart.appear(1000, 100);

            return () => {
                root.dispose();
            };
        }
    }, [data, color]);

    return (
        <div ref={chartRef} className={classes.chartContainer}></div>
    );
}

export default ChartTickets;