import React, { useState, useEffect } from "react";
import makeStyles from '@mui/styles/makeStyles';
import { useHistory } from "react-router-dom";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";

import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Autocomplete, {
    createFilterOptions,
} from '@mui/material/Autocomplete';
import CircularProgress from "@mui/material/CircularProgress";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import UserStatusIcon from "../User/statusIcon";

const filterOptions = createFilterOptions({
    trim: true,
});

const useStyles = makeStyles(theme => ({
    online: {
        background: 'green',
        borderRadius: '50%',
        width: '10px',
        height: '10px',
        display: 'inline-block'
    },
    offline: {
        background: 'red',
        borderRadius: '50%',
        width: '10px',
        height: '10px',
        display: 'inline-block'
    }
}));

const TransferTicketModal = ({ modalOpen, onClose, ticket }) => {
    const classes = useStyles();
    const history = useHistory();
    const [options, setOptions] = useState([]);
    const [queues, setQueues] = useState([]);
    const [whatsapps, setWhatsapps] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingWhatsapp, setLoadingWhatsapp] = useState(false);
    const [loadingQueue, setLoadingQueue] = useState(false);
    const [searchParam, setSearchParam] = useState("");
    const [searchParamWhatsapp, setSearchParamWhatsapp] = useState("");
    const [searchParamQueue, setSearchParamQueue] = useState("");
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedQueue, setSelectedQueue] = useState(null);
    const [selectedWhatsapp, setSelectedWhatsapp] = useState(null);
    const ticketid = ticket?.id;

    const setCurrentDefaultWhatsapp = () => {
        if (ticket && ticket.whatsappId) {
            const currentWhatsapp = whatsapps.find((value) => { return value.id == ticket?.whatsappId });
            if (currentWhatsapp) {
                setSelectedWhatsapp(currentWhatsapp);
            }
        }
    }

    const setCurrentDefaultQueue = () => {
        if (ticket && ticket.queueId) {
            const currentQueue = queues.find((value) => { return value.id == ticket?.queueId });
            if (currentQueue) {
                setSelectedQueue(currentQueue);
            }
        }
    }

    const fetchUsers = async () => {
        try {
            let groupIds = [1];
    
            if (!ticket.queue || ticket.status === 'group') {
                const fetchPermissions = async () => {
                    try {
                        const { data } = await api.get("/role/permissions/check", {
                            params: {
                                key: ticket.status === 'group' ? 'contacts:groups:see-all':'tickets:show:without:queue'
                            },
                        });
                        //console.log(data, 'response check');
                        groupIds = data.roleIds
                    } catch (err) {
                        toastError(err);
                    }
                };
    
                await fetchPermissions();
            }
    
            const { data } = await api.get("/users/", {
                params: { searchParam, queueId: selectedQueue?.id, whatsappId: selectedWhatsapp?.id },
            });
    
            let filteredUsers = data.users.filter(user => user.enabled);
            if (!ticket.queue && !selectedQueue && groupIds.length > 0) {
                groupIds = [...groupIds, 1]
                filteredUsers = filteredUsers.filter(user => groupIds.includes(user.roleId));
            }
    
            setOptions(filteredUsers);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            toastError(err);
        }
    };


    useEffect(() => {
        if (!selectedQueue) {
            setCurrentDefaultQueue();
        }
    }, [queues, ticket]);

    useEffect(() => {
        if (!selectedWhatsapp) {
            setCurrentDefaultWhatsapp();
        }
    }, [whatsapps, ticket]);

    useEffect(() => {
        if (!modalOpen) {
            setLoading(false);
            return;
        }
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            fetchUsers();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [searchParam, modalOpen, selectedQueue]);

    useEffect(() => {
        if (!modalOpen) {
            setLoadingQueue(false);
            return;
        }
        setLoadingQueue(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchQueues = async () => {
                try {
                    const { data } = await api.get("/queue/", {
                        params: { searchParam: searchParamQueue, whatsappId: selectedWhatsapp?.id },
                    });
                    setQueues(data);
                    setLoadingQueue(false);
                } catch (err) {
                    setLoadingQueue(false);
                    toastError(err);
                }
            };

            fetchQueues();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [searchParamQueue, modalOpen]);



    useEffect(() => {
        if (!modalOpen) {
            setLoadingWhatsapp(false);
            return;
        }
        setLoadingWhatsapp(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchWhatsapps = async () => {
                try {
                    const { data } = await api.get("/whatsapp/", {
                        params: { searchParam: searchParamWhatsapp, listAll: true },
                    });
                    setWhatsapps(data.whatsapps);

                    setLoadingWhatsapp(false);
                } catch (err) {
                    setLoadingWhatsapp(false);
                    toastError(err);
                }
            };

            fetchWhatsapps();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [searchParamWhatsapp, modalOpen]);

    const handleClose = () => {
        onClose();
        setSearchParam("");
        setSelectedUser(null);
    };

    const handleSetSelectedWhatsapp = (value) => {
        setSelectedWhatsapp(value);
    };

    const handleSaveTicket = async e => {
        e.preventDefault();
        if (!ticketid || (!selectedUser && !selectedWhatsapp && !selectedQueue)) return;
        //console.log('selectedWhatsapp: ', selectedWhatsapp)
        setLoading(true);
        try {
            await api.post(`/tickets/${ticketid}/transfer`, {
                userId: selectedUser?.id,
                queueId: selectedQueue?.id,
                whatsappId: selectedWhatsapp?.id,
                status: "open",
            });
            setLoading(false);
            history.push(`/tickets`);
        } catch (err) {
            setLoading(false);
            toastError(err);
        }
    };

    return (
        <Dialog open={modalOpen} onClose={handleClose} maxWidth="lg" scroll="paper">
            <form onSubmit={handleSaveTicket}>
                <DialogTitle id="form-dialog-title">
                    {i18n.t("transferTicketModal.title")}
                </DialogTitle>
                <DialogContent dividers>
                    {ticket.status !== "group" && <Autocomplete
                        style={{ width: 300, marginBottom: 10 }}
                        getOptionLabel={option => `${option.name}`}
                        onChange={(e, newValue) => {
                            handleSetSelectedWhatsapp(newValue);
                        }}
                        options={whatsapps}
                        filterOptions={filterOptions}
                        value={selectedWhatsapp}
                        freeSolo
                        autoHighlight
                        noOptionsText={i18n.t("transferTicketModal.noOptions")}
                        loading={loadingWhatsapp}
                        renderOption={(props, option) => (
                            <Box component="li" {...props}>
                                <span>{option.name} {option.status == 'CONNECTED' ? <span>
                                    <span className={classes.online}></span></span> : <span><span className={classes.offline}></span></span>}</span>
                            </Box>
                        )}
                        renderInput={params => (
                            <TextField
                                {...params}
                                label={i18n.t("transferTicketModal.fieldLabelWhatsapp")}
                                variant="outlined"
                                autoFocus
                                onChange={e => setSearchParamWhatsapp(e.target.value)}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {loading ? (
                                                <CircularProgress color="inherit" size={20} />
                                            ) : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />}
                    {ticket.status !== "group" &&
                        <Autocomplete
                            style={{ width: 300, marginBottom: 10 }}
                            getOptionLabel={option => `${option.name}`}
                            onChange={(e, newValue) => {
                                setSelectedQueue(newValue);
                            }}
                            options={queues}
                            filterOptions={filterOptions}
                            freeSolo
                            autoHighlight
                            noOptionsText={i18n.t("transferTicketModal.noOptions")}
                            loading={loadingQueue}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    label={i18n.t("transferTicketModal.fieldLabelQueue")}
                                    variant="outlined"
                                    autoFocus
                                    onChange={e => setSearchParamQueue(e.target.value)}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {loading ? (
                                                    <CircularProgress color="inherit" size={20} />
                                                ) : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                        />}
                    <Autocomplete
                        style={{ width: 300 }}
                        getOptionLabel={option => `${option.name}`}
                        onChange={(e, newValue) => {
                            setSelectedUser(newValue);
                        }}
                        options={options}
                        filterOptions={filterOptions}
                        freeSolo
                        autoHighlight
                        noOptionsText={i18n.t("transferTicketModal.noOptions")}
                        loading={loading}
                        renderOption={(props, option) => (
                            <Box component="li" {...props}>
                                <span>{option.name} <UserStatusIcon user={option} /></span>
                            </Box>
                        )}
                        renderInput={params => (
                            <TextField
                                {...params}
                                label={i18n.t("transferTicketModal.fieldLabel")}
                                variant="outlined"
                                autoFocus
                                onChange={e => setSearchParam(e.target.value)}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {loading ? (
                                                <CircularProgress color="inherit" size={20} />
                                            ) : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        color="secondary"
                        disabled={loading}
                        variant="outlined"
                    >
                        {i18n.t("transferTicketModal.buttons.cancel")}
                    </Button>
                    <ButtonWithSpinner
                        variant="contained"
                        type="submit"
                        color="primary"
                        loading={loading}
                    >
                        {i18n.t("transferTicketModal.buttons.ok")}
                    </ButtonWithSpinner>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default TransferTicketModal;
