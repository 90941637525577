import React, { useState, useEffect } from "react";
import { ResponsiveBar } from "@nivo/bar";

const BarChart2 = ({ data, title, theme }) => {
    const [formattedChartData, setFormattedChartData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            if (data.length === 0) return; // Return if data is empty
            //console.log('the data before', data)
            const summedData = data.reduce((acc, curr) => {
                const existingEntry = acc.find(item => item.name === curr.name);
                if (existingEntry) {
                    existingEntry.total += (curr.qtdTickets|| curr.qtdMsg);
                } else {
                    acc.push({ name: curr.name, total: (curr.qtdTickets || curr.qtdMsg)});
                }
                return acc;
            }, []);

            //console.log('the data', summedData)
            setFormattedChartData(summedData);
        }
        fetchData();
    }, [data]);

    return (
        <ResponsiveBar
            data={formattedChartData}
            keys={['total']}
            indexBy={['name']}
            valueFormat={['total']}
            height={500}
            margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
            padding={0.3}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={{ scheme: theme }}
            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            axisTop={null}
            axisRight={null}
            axisBottom={{ 
                tickSize: 5,
                tickPadding: 0,
                tickRotation: -15,
                legendPosition: 'start',
                legendOffset:2,
                truncateTickAt: 100
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: title,
                format: value => value !== 0 ? `${value.toFixed(0)}` : null,
                legendPosition: 'middle',
                legendOffset: -40,
                truncateTickAt: 0,
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            legends={[
                {
                    dataFrom: 'keys',
                    anchor: 'top-right',
                    direction: 'column',
                    justify: false,
                    translateX: 100,
                    translateY: 0,
                    itemsSpacing: 4,
                    itemWidth: 100,
                    itemHeight: 10,
                    itemDirection: 'left-to-right',
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    effects: [{ on: 'hover', style: { itemOpacity: 1 } }]
                }
            ]}
            role="application"
            ariaLabel="Nivo bar chart demo"
            barAriaLabel={(e) => e.id + ': ' + e.formattedValue + ' in category: ' + e.indexValue}
        />
    )
}

export default BarChart2;
