import React, { useState, useEffect, useContext, useRef } from "react";
import "emoji-mart/css/emoji-mart.css";
import { useParams } from "react-router-dom";
import { Picker } from "emoji-mart";
import MicRecorder from "mic-recorder-to-mp3";
import clsx from "clsx";
import makeStyles from '@mui/styles/makeStyles';
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Campaign, LoopOutlined, Timer } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import MoreVert from "@mui/icons-material/MoreVert";
import MoodIcon from "@mui/icons-material/Mood";
import SendIcon from "@mui/icons-material/Send";
import CancelIcon from "@mui/icons-material/Cancel";
import ClearIcon from "@mui/icons-material/Clear";
import MicIcon from "@mui/icons-material/Mic";
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { FormControlLabel, Hidden, Menu, MenuItem, Switch } from "@mui/material";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import ScheduledMessageModal from "../ScheduledMessageModal";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import RecordingTimer from "./RecordingTimer";
import { ReplyMessageContext } from "../../context/ReplyingMessage/ReplyingMessageContext";
import { EditMessageContext } from "../../context/EditingMessage/EditingMessageContext";
import { AuthContext } from "../../context/Auth/AuthContext";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import toastError from "../../errors/toastError";
import ConfirmationModal from "../ConfirmationModal";
import { SettingsContext } from "../../context/Settings/SettingsContext";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import Swal from "sweetalert2";
import PromptChatAIModal from "../PromptChatAIModal";
import { AiOutlineGif } from "react-icons/ai";
import { PiSticker } from "react-icons/pi";
import ReactGiphySearchbox from 'react-giphy-searchbox-techedge';
import { Can } from "../Can";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const useStyles = makeStyles(theme => ({
    mainWrapper: {
        background: theme.palette.tabHeaderBackground,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
        [theme.breakpoints.down('md')]: {
            position: "fixed",
            bottom: 0,
            width: "100%",
            zIndex: 9,
            left: 0
        }
    },
    searchboxGifWrapper: {
        background: theme.palette.tabHeaderBackground,
        borderRadius: 10,
        display: 'inline-grid',
        padding: 15,
        ...theme.scrollbarStylesSoft
    },
    newMessageBox: {
        background: theme.palette.tabHeaderBackground,
        width: "100%",
        display: "flex",
        padding: "7px",
        alignItems: "center",
    },
    messageInputWrapper: {
        padding: 6,
        marginRight: 7,
        background: theme.palette.total,
        display: "flex",
        borderRadius: 20,
        flex: 1,
        position: "relative"
    },
    messageInput: {
        paddingLeft: 10,
        flex: 1,
        border: "none",
    },
    sendMessageIcons: {
        color: theme.palette.messageIcons,
    },
    uploadInput: {
        display: "none",
    },
    viewMediaInputWrapper: {
        display: "flex",
        padding: "10px 13px",
        position: "relative",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: theme.palette.tabHeaderBackground,
        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    },
    emojiBox: {
        position: "absolute",
        bottom: 63,
        width: 40,
        borderTop: "1px solid #e8e8e8",
    },
    circleLoading: {
        color: green[500],
        opacity: "70%",
        position: "absolute",
        top: "20%",
        left: "50%",
        marginLeft: -12,
    },
    audioLoading: {
        color: green[500],
        opacity: "70%",
    },
    recorderWrapper: {
        display: "flex",
        alignItems: "center",
        alignContent: "middle",
    },
    cancelAudioIcon: {
        color: "red",
    },
    sendAudioIcon: {
        color: "green",
    },
    replyginMsgWrapper: {
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: 8,
        paddingLeft: 73,
        paddingRight: 7,
    },
    replyginMsgContainer: {
        flex: 1,
        marginRight: 5,
        overflowY: "hidden",
        backgroundColor: "rgba(0, 0, 0, 0.05)",
        borderRadius: "7.5px",
        display: "flex",
        position: "relative",
    },
    replyginMsgBody: {
        padding: 10,
        height: "auto",
        display: "block",
        whiteSpace: "pre-wrap",
        overflow: "hidden",
    },
    replyginContactMsgSideColor: {
        flex: "none",
        width: "4px",
        backgroundColor: "#35cd96",
    },
    replyginSelfMsgSideColor: {
        flex: "none",
        width: "4px",
        backgroundColor: "#6bcbef",
    },
    messageContactName: {
        display: "flex",
        color: "#6bcbef",
        fontWeight: 500,
    },
    messageShortcutWrapper: {
        margin: 0,
        position: "absolute",
        bottom: "50px",
        background: theme.palette.total,
        padding: "2px",
        border: "1px solid #CCC",
        left: 0,
        width: "100%",
        "& li": {
            listStyle: "none",
            "&:focus-within a, & a": {
                display: "block",
                padding: "8px",
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxHeight: "32px",
                textDecoration: "none",
                color: theme.palette.dark.main,
                "&:hover, &:active": {
                    background: theme.palette.primary.main,
                    color: "#FFF",
                    cursor: "pointer"
                }
            }
        }
    },
    formControl: {
        marginRight: 7,
        color: theme.palette.messageIcons
    },
    blockIcons: {
        background: theme.palette.tabHeaderBackground,
        position: 'absolute',
        borderRadius: 20,
        bottom: 50,
        zIndex: 99999
    },
    headerIcons: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%'
    },
    carouselContainer: {
        display: "flex",
        overflowX: "auto",
        width: "100%",
        padding: "10px",
        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    },
    carouselItem: {
        minWidth: "100px",
        maxWidth: "100px",
        height: "100px",
        marginRight: "10px",
        backgroundColor: "#f0f0f0",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "5px",
        border: "1px solid #ccc",
        position: "relative",
        backgroundSize: "cover",
        backgroundPosition: "center",
    },
    removeIcon: {
        position: "absolute",
        top: "5px",
        right: "5px",
        color: "#f00",
        cursor: "pointer",
    },
    carouselName: {
        fontSize: 10,
    },
    carouselItemUp: {
        display: "block",
        minWidth: "100px",
        maxWidth: "100px",
        overflow: "hidden",
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        margin: 4,
    }
}));

const MessageInput = ({ ticketStatus, droppedFiles, contact }) => {
    const classes = useStyles();
    const { ticketId } = useParams();
    const fileInputRef = useRef(null);
    const { isActive } = useContext(SettingsContext);

    const [medias, setMedias] = useState([]);
    const [inputMessage, setInputMessage] = useState("");
    const [caption, setCaption] = useState("");
    const [showEmojiBlock, setShowEmojiBlock] = useState(false);
    const [showEmoji, setShowEmoji] = useState(false);
    const [showStickers, setShowStickers] = useState(false);
    const [showGif, setShowGif] = useState(false);
    const [mediaType, setMediaType] = useState('');
    const [loading, setLoading] = useState(false);
    const [recording, setRecording] = useState(false);
    const [typeBar, setTypeBar] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const inputRef = useRef();
    const [anchorEl, setAnchorEl] = useState(null);
    const [loadMessagesConfirmationOpen, setLoadMessagesConfirmationOpen] = useState(false);
    const [uploadFromShortcut, setUploadFromShortcut] = useState(false);
    const [appointmentModalOpen, setAppointmentModalOpen] = useState(false);
    const [promptAIModalOpen, setPromptAIModalOpen] = useState(false);
    const [blockEnter, setBlockEnter] = useState(false);
    const { getSettingValue } = useContext(SettingsContext);
    const { setReplyingMessage, replyingMessage } = useContext(ReplyMessageContext);
    const { setEditingMessage, editingMessage, setEditedMessage } = useContext(EditMessageContext);
    const { user } = useContext(AuthContext);
    const keyOpenAi = getSettingValue('keyOpenAi');
    const shortcutMessagesListRef = useRef();
    const [signMessage, setSignMessage] = useLocalStorage("signOption", true);

    useEffect(() => {
        if (inputRef && inputRef.current) {
            inputRef.current.focus();
        }
    }, [replyingMessage]);

    useEffect(() => {
        if (inputRef && inputRef.current) {
            inputRef.current.focus();
        }
        return () => {
            setInputMessage("");
            setShowEmoji(false);
            setShowGif(false);
            setMedias([]);
            setReplyingMessage(null);
            setEditingMessage(null);
        };
    }, [ticketId, setReplyingMessage, setEditingMessage]);

    useEffect(() => {
        setMedias(droppedFiles);
    }, [droppedFiles]);

    const handleChangeInput = e => {
        setInputMessage(e.target.value);
        const hasDirectMedia = medias.some(media => media.source === "direct");
        if (!hasDirectMedia) {
            setCaption(e.target.value);
        }
        handleLoadShortcutMessages(e.target.value);
    };
    const [suggestionImageUrl, setSuggestionImageUrl] = useState('');

    const handleSuggestionClick = value => {
        if (value.mediaUrl) {
            (async () => {
                const res = await fetch(value.absoluteMediaUrl);
                const blob = await res.blob();
                const contentType = res.headers.get('content-type');
                const newFile = new File([blob], value.mediaUrl, { type: contentType });
                newFile.source = 'suggestion';
                setMedias([...medias, newFile]);
                setSuggestionImageUrl(value.mediaUrl);
                setCaption(value.content);
                setInputMessage(value.content);
                setUploadFromShortcut(true);
            })();
        } else {
            setInputMessage(value.content);
        }
        setTypeBar(false);
        setTimeout(() => {
            setBlockEnter(false);
        }, 500);
    }


    const handleAddEmoji = e => {
        let emoji = e.native;
        setShowEmoji(false);
        setInputMessage(prevState => prevState + emoji);
    };

    const handleAddGif = async e => {
        if (e.images.original) {
            let gifUrl = e.images.original.mp4;
            const response = await fetch(gifUrl);
            const blob = await response.blob();
            const gifFile = new File([blob], 'file.mp4', { type: blob.type });
            setMediaType('gif');
            handleChangeMedias([gifFile]);
        }
    };

    const handleAddStickers = async e => {
        if (e.images.original) {
            let gifUrl = e.images.original.webp;
            const response = await fetch(gifUrl);
            const blob = await response.blob();
            const stickerfFile = new File([blob], 'sticker.webp', { type: blob.type });
            setMediaType('sticker');
            handleChangeMedias([stickerfFile]);
        }
    };

    const handleChangeMediasDirectly = async (e) => {
        if (!e.target.files) {
            return;
        }

        const selectedMedias = Array.from(e.target.files);

        const updatedMedias = await Promise.all(
            selectedMedias.map((file) => {
                return new Promise((resolve) => {
                    if (file.type.startsWith('image/')) {
                        const reader = new FileReader();
                        reader.onload = (readerEvent) => {
                            file.preview = readerEvent.target.result;
                            file.source = 'direct';
                            resolve(file);
                        };
                        reader.readAsDataURL(file);
                    } else {
                        file.source = 'direct';
                        resolve(file);
                    }
                });
            })
        );
        setMedias(updatedMedias);
        fileInputRef.current.value = '';
    };


    const handleChangeMedias = (files) => {
        if (!files || files.length === 0) {
            return;
        }

        const selectedMedias = [...medias, ...files];

        files.forEach(file => {
            if (file.type.startsWith('image/')) {
                const reader = new FileReader();
                reader.onload = (readerEvent) => {
                    file.preview = readerEvent.target.result;
                    setMedias(selectedMedias);
                };
                reader.readAsDataURL(file);
            }

            if (file.type.startsWith('video/')) {
                const reader = new FileReader();
                reader.onload = (readerEvent) => {
                    file.preview = readerEvent.target.result;
                    setMedias(selectedMedias);
                };
                reader.readAsDataURL(file);
            }
        });
    };

    const handleInputPaste = e => {
        if (e.clipboardData.files[0]) {
            setMedias([e.clipboardData.files[0]]);
        }
    };

    const handleUploadMedia = async e => {
        setLoading(true);
        e.preventDefault();

        const formData = new FormData();
        formData.append("fromMe", true);
        medias.forEach(media => {
            formData.append("medias", media);
            formData.append("body", caption);
            formData.append("mediaType", mediaType);
        });

        if (uploadFromShortcut) {
            formData.append("asAudio", true);
        }

        try {
            await api.post(`/messages/${ticketId}`, formData);
        } catch (err) {
            const errorMsg = err.response?.status;
            Swal.fire({
                title: errorMsg === 410 ? "Contato bloqueado" : "Error!",
                text: getErrorMessage(err),
                icon: 'error',
                confirmButtonText: "OK"
            });
        }

        setLoading(false);
        setUploadFromShortcut(false);
        setMedias([]);
        setMediaType('');
        setSuggestionImageUrl('');
        setCaption("");
    };

    const handleSendMessage = async () => {
        let currentInputMessage = inputMessage;

        if (caption !== "" && medias.length) {
            setInputMessage("");
            currentInputMessage = "";
        }

        if (currentInputMessage.trim() === "" && medias.length === 0) return;
        setLoading(true);

        const cantDisableSignMessage = !isActive('showDisableSignOption') && user.profile !== "admin";

        if (currentInputMessage.trim() !== "") {
            const message = {
                read: 1,
                fromMe: true,
                mediaUrl: "",
                body: signMessage || cantDisableSignMessage
                    ? `*${user?.name}:*\n${currentInputMessage.trim()}`
                    : currentInputMessage.trim(),
                quotedMsg: replyingMessage
            };
            try {
                await api.post(`/messages/${ticketId}`, message);
            } catch (err) {
                const errorMsg = err.response?.status;
                Swal.fire({
                    title: errorMsg === 410 ? "Este contato está bloqueado" : "Error!",
                    text: getErrorMessage(err),
                    icon: 'error',
                    confirmButtonText: "OK"
                });
            }
            setInputMessage("");
        }

        setInputMessage("");
        setLoading(false);
        setReplyingMessage(null);
        setMedias([]);
        setCaption("");

        if (medias.length > 0) {
            await handleUploadQueue();
        } else {
            setLoading(false);
            setReplyingMessage(null);
        }
    };

    const handleSendEditedMessage = async () => {
        if (inputMessage.trim() === "") return;
        setLoading(true);

        const cantDisableSignMessage = !isActive('showDisableSignOption') && user.profile !== "admin";

        const data = {
            newText: signMessage || cantDisableSignMessage
                ? `*${user?.name}:*\n${inputMessage.trim()}`
                : inputMessage.trim(),
            message: replyingMessage,
            ticketId
        }
        try {
            const response = await api.put(`/messages/${editingMessage.id}/edit`, data);
            if (response) {
                setEditedMessage(true);
            }
        } catch (err) {
            Swal.fire({
                title: "Error!",
                text: getErrorMessage(err),
                icon: 'error',
                confirmButtonText: "OK"
            });
        }
        setInputMessage("");
        setShowEmoji(false);
        setShowGif(false);
        setLoading(false);
        setEditingMessage(null);
    }

    const getErrorMessage = (err) => {
        const errorMsg = err.response?.data?.error;
        if (i18n.exists(`backendErrors.${errorMsg}`)) {
            return i18n.t(`backendErrors.${errorMsg}`);
        } else {
            return errorMsg;
        }
    }

    const handleLoadShortcutMessages = async (value) => {
        if (value && value.indexOf('/') === 0) {
            try {
                const queueIdList = user.queues.map(queue => queue.id);
                let queueIds;
                if (user.queues) {
                    queueIds = user.queues.map(queue => queue.id);
                }
                const { data } = await api.get('/shortcut-messages/', {
                    params: { searchParam: inputMessage.substring(1), queueIds }
                });

                let filteredShortcuts = [];

                if (data.shortcutMessages) {
                    filteredShortcuts = data.shortcutMessages;
                }

                setSuggestions(filteredShortcuts);

                if (data.shortcutMessages.length > 0) {
                    setTypeBar(true);
                } else {
                    setTypeBar(false);
                }
            } catch (err) {
                setTypeBar(false);
            }
        } else {
            setTypeBar(false);
        }
    }

    const [mediaRecorder, setMediaRecorder] = useState(null);

    const handleStartRecording = async () => {
        setLoading(true);
        try {
            await navigator.mediaDevices.getUserMedia({ audio: true });
            await Mp3Recorder.start();
            setRecording(true);
            setLoading(false);
        } catch (err) {
            toastError(err);
            setLoading(false);
        }
    };

    const handleUploadAudio = async () => {
        setLoading(true);
        try {
            const [, blob] = await Mp3Recorder.stop().getMp3();
            if (blob.size < 10000) {
                setLoading(false);
                setRecording(false);
                return;
            }
            const formData = new FormData();
            const filename = `${new Date().getTime()}.mp3`;
            formData.append("medias", blob, filename);
            formData.append("body", filename);
            formData.append("fromMe", true);
            formData.append("isAudio", true);

            await api.post(`/messages/${ticketId}`, formData);
        } catch (err) {
            toastError(err);
        }

        setRecording(false);
        setLoading(false);
    };

    const handleCancelAudio = async () => {
        try {
            await Mp3Recorder.stop().getMp3();
            setRecording(false);
        } catch (err) {
            toastError(err);
        }
    };

    const handleAIMessage = (message) => {
        if (message) {
            setInputMessage(message)
        }
    }

    const handleOpenMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleMenuItemClick = (event) => {
        setAnchorEl(null);
    }

    const handleReloadMessages = (event) => {
        setLoadMessagesConfirmationOpen(true);
    }

    const handleLoadMessages = async (event) => {
        try {
            await api.post(`/tickets/${ticketId}/load-messages`);
        } catch (err) {
            toastError(err);
        }
    }

    let shortcutCurrentNavIndex = -1;

    const handleShortcutKeyPressEvent = (event) => {
        if (typeBar && event.key === "ArrowUp" || event.key === "ArrowDown") {
            setBlockEnter(true);

            const itemsCount = shortcutMessagesListRef.current ? shortcutMessagesListRef.current.children.length : 0;
            if (event.key === "ArrowDown") {
                shortcutCurrentNavIndex++;
                if (shortcutCurrentNavIndex >= itemsCount) {
                    shortcutCurrentNavIndex = 0;
                }
            } else {
                shortcutCurrentNavIndex--;
                if (shortcutCurrentNavIndex < 0) {
                    shortcutCurrentNavIndex = itemsCount - 1;
                }
            }
            if (shortcutMessagesListRef && shortcutMessagesListRef.current) {
                const children = shortcutMessagesListRef.current.children;
                if (children && children.length > shortcutCurrentNavIndex) {
                    children[shortcutCurrentNavIndex].focus();
                }
            }
        }
    }

    const handleShortcutKeyPressStart = (event) => {
        shortcutCurrentNavIndex = -1;
        handleShortcutKeyPressEvent(event);
    }

    const handleShortcutKeyPress = (event) => {
        if (typeBar && event.key === "Enter") {
            handleSuggestionClick(suggestions[shortcutCurrentNavIndex]);
        }
        handleShortcutKeyPressEvent(event);
    }

    const handleOpenEmojis = () => {
        setShowEmojiBlock(prevState => !prevState);
        setShowEmoji(true);
        setShowStickers(false)
        setShowGif(false)
    }


    const handleClickAwayEmoji = () => {
        //console.log("Clicked Away");
        handleOpenEmojis();
        //setShowEmoji(false);
        //setShowStickers(false);
        //setShowGif(false);
    };


    const renderReplyingMessage = message => {
        return (
            <div className={classes.replyginMsgWrapper}>
                <div className={classes.replyginMsgContainer}>
                    <span
                        className={clsx(classes.replyginContactMsgSideColor, {
                            [classes.replyginSelfMsgSideColor]: !message.fromMe,
                        })}
                    ></span>
                    <div className={classes.replyginMsgBody}>
                        {!message.fromMe && (
                            <span className={classes.messageContactName}>
                                {message.contact?.name}
                            </span>
                        )}
                        {message.body}
                    </div>
                </div>
                <IconButton
                    aria-label="showRecorder"
                    component="span"
                    disabled={loading || (ticketStatus !== "open" && ticketStatus !== "group")}
                    onClick={() => setReplyingMessage(null)}
                    size="large">
                    <ClearIcon className={classes.sendMessageIcons} />
                </IconButton>
            </div>
        );
    };

    const renderEditingMessage = message => {
        return (
            <div className={classes.replyginMsgWrapper}>
                <div className={classes.replyginMsgContainer}>
                    <span
                        className={clsx(classes.replyginContactMsgSideColor, {
                            [classes.replyginSelfMsgSideColor]: !message.fromMe,
                        })}
                    ></span>
                    <div className={classes.replyginMsgBody}>
                        {!message.fromMe && (
                            <span className={classes.messageContactName}>
                                {message.contact?.name}
                            </span>
                        )}
                        {message.body}
                    </div>
                </div>
                <IconButton
                    aria-label="showRecorder"
                    component="span"
                    disabled={loading || (ticketStatus !== "open" && ticketStatus !== "group")}
                    onClick={() => setEditingMessage(null)}
                    size="large">
                    <ClearIcon className={classes.sendMessageIcons} />
                </IconButton>
            </div>
        );
    };

    const handleChangeTypeIcons = (type) => {
        if (type === 'emoji') {
            setShowEmoji(true)
            setShowGif(false)
            setShowStickers(false)
        } else if (type === 'gif') {
            setShowGif(true)
            setShowEmoji(false)
            setShowStickers(false)
        } else {
            setShowStickers(true)
            setShowEmoji(false)
            setShowGif(false)
        }
    }

    const handleUploadQueue = async () => {
        if (medias.length === 0) return;

        setLoading(true);
        const file = medias.shift();
        setMedias(medias);

        const formData = new FormData();
        formData.append("fromMe", true);
        formData.append("medias", file);
        formData.append("body", caption);
        formData.append("mediaType", mediaType);

        try {
            await api.post(`/messages/${ticketId}`, formData);
            if (medias.length > 0) {
                handleUploadQueue();
            } else {
                setLoading(false);
                setReplyingMessage(null);
            }
        } catch (err) {
            Swal.fire({
                title: "Error!",
                text: getErrorMessage(err),
                icon: 'error',
                confirmButtonText: "OK"
            });
            setLoading(false);
        }
    };

    return (
        <Paper square elevation={0} className={classes.mainWrapper}>
            <ScheduledMessageModal
                open={appointmentModalOpen}
                onClose={() => setAppointmentModalOpen(false)}
                message={inputMessage}
                fromTicket={true}
                contact={contact}
            />
            <PromptChatAIModal
                open={promptAIModalOpen}
                onClose={() => setPromptAIModalOpen(false)}
                message={inputMessage}
                fromTicket={true}
                contact={contact}
                handleAIMessage={handleAIMessage}
            />
            {replyingMessage && renderReplyingMessage(replyingMessage)}
            {editingMessage && renderEditingMessage(editingMessage)}
            <div className={classes.newMessageBox}>
                {showEmojiBlock ? (
                    <div className={classes.blockIcons}>
                        <ClickAwayListener onClickAway={e => setShowEmojiBlock(false)}>
                            <>
                                <div className={classes.headerIcons}>
                                    <IconButton
                                        aria-label="emojiPicker"
                                        component="span"
                                        disabled={loading || recording || (ticketStatus !== "open" && ticketStatus !== "group")}
                                        onClick={e => handleChangeTypeIcons('emoji')}
                                        size="large">
                                        <MoodIcon className={classes.sendMessageIcons} />
                                    </IconButton>

                                    <IconButton
                                        aria-label="gifPicker"
                                        component="span"
                                        disabled={loading || recording || (ticketStatus !== "open" && ticketStatus !== "group")}
                                        onClick={e => handleChangeTypeIcons('gif')}
                                        size="large">
                                        <AiOutlineGif className={classes.sendMessageIcons} />
                                    </IconButton>

                                    <IconButton
                                        aria-label="stickerPicker"
                                        component="span"
                                        disabled={loading || recording || (ticketStatus !== "open" && ticketStatus !== "group")}
                                        onClick={e => handleChangeTypeIcons('stickers')}
                                        size="large">
                                        <PiSticker className={classes.sendMessageIcons} />
                                    </IconButton>

                                    <IconButton
                                        aria-label="closeEmojiBlock"
                                        component="span"
                                        onClick={e => setShowEmojiBlock(false)}
                                        size="large">
                                        <CloseIcon className={classes.sendMessageIcons} />
                                    </IconButton>
                                </div>

                                {showEmojiBlock && showEmoji && (
                                    <Picker
                                        perLine={16}
                                        showPreview={false}
                                        showSkinTones={false}
                                        onSelect={handleAddEmoji}
                                        width={300}
                                    />
                                )}

                                {showEmojiBlock && showGif && (
                                    <div className={classes.searchboxGifWrapper}>
                                        <ReactGiphySearchbox
                                            apiKey="9Ixlv3DWC1biJRI57RanyL7RTbfzz0o7"
                                            onSelect={(item) => handleAddGif(item)}
                                            masonryConfig={[
                                                { columns: 2, imageWidth: 110, gutter: 5 },
                                                { mq: "700px", columns: 3, imageWidth: 100, gutter: 5 }
                                            ]}
                                        />
                                    </div>
                                )}

                                {showEmojiBlock && showStickers && (
                                    <div className={classes.searchboxGifWrapper}>
                                        <ReactGiphySearchbox
                                            apiKey="9Ixlv3DWC1biJRI57RanyL7RTbfzz0o7"
                                            onSelect={(item) => handleAddStickers(item)}
                                            library="stickers"
                                            masonryConfig={[
                                                { columns: 2, imageWidth: 110, gutter: 5 },
                                                { mq: "700px", columns: 3, imageWidth: 100, gutter: 5 }
                                            ]}
                                        />
                                    </div>
                                )}
                            </>
                        </ClickAwayListener>
                    </div>
                ) : null}
                <ConfirmationModal
                    title="Confirmar Carregamento de todas as mensagens"
                    open={loadMessagesConfirmationOpen}
                    onClose={setLoadMessagesConfirmationOpen}
                    onConfirm={handleLoadMessages}
                >
                    Tem certeza que deseja carregar todas as mensagens desse atendimento? O processo é feito de forma assíncrona e pode levar alguns minutos até ser concluído.
                    Para manter a estabilidade do sistema, não realize esse procedimento frequentemente.
                </ConfirmationModal>
                <Hidden only={['sm', 'xs']}>
                    <IconButton
                        aria-label="emojiPicker"
                        component="span"
                        disabled={loading || recording || (ticketStatus !== "open" && ticketStatus !== "group")}
                        onClick={handleOpenEmojis}
                        size="large">
                        <MoodIcon className={classes.sendMessageIcons} />
                    </IconButton>

                    <input
                        multiple
                        type="file"
                        id="upload-button"
                        ref={fileInputRef}
                        className={classes.uploadInput}
                        onChange={handleChangeMediasDirectly}
                    />

                    <label htmlFor="upload-button">
                        <IconButton
                            aria-label="upload"
                            component="span"
                            disabled={loading || recording || (ticketStatus !== "open" && ticketStatus !== "group")}
                            size="large">
                            <AttachFileIcon className={classes.sendMessageIcons} />
                        </IconButton>
                    </label>
                    <Can
                        role={user.roleId}
                        perform="tickets:signature:allow"
                        yes={() => (
                            <>
                                <FormControlLabel
                                    label={i18n.t("messagesInput.signMessage")}
                                    className={classes.formControl}
                                    labelPlacement="start"
                                    control={
                                        <Switch
                                            size="small"
                                            checked={signMessage}
                                            onChange={e => {
                                                setSignMessage(e.target.checked);
                                            }}
                                            name="showAllTickets"
                                            color="primary"
                                        />
                                    }
                                />
                            </>                        
                        )}
                        no={() => <></>}
                        />
                </Hidden>
                <Hidden only={['md', 'lg', 'xl']}>
                    <IconButton
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={handleOpenMenuClick}
                        size="large">
                        <MoreVert></MoreVert>
                    </IconButton>
                    <Menu
                        id="simple-menu"
                        keepMounted
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuItemClick}
                    >
                        <MenuItem onClick={handleMenuItemClick}>
                            <IconButton
                                aria-label="emojiPicker"
                                component="span"
                                disabled={loading || recording || (ticketStatus !== "open" && ticketStatus !== "group")}
                                onClick={handleOpenEmojis}
                                size="large">
                                <MoodIcon className={classes.sendMessageIcons} />
                            </IconButton>
                        </MenuItem>
                        <MenuItem onClick={handleMenuItemClick}>
                            <input
                                multiple
                                type="file"
                                id="upload-button"
                                ref={fileInputRef}
                                className={classes.uploadInput}
                                onChange={handleChangeMediasDirectly}
                            />
                            <label htmlFor="upload-button">
                                <IconButton
                                    aria-label="upload"
                                    component="span"
                                    disabled={loading || recording || (ticketStatus !== "open" && ticketStatus !== "group")}
                                    size="large">
                                    <AttachFileIcon className={classes.sendMessageIcons} />
                                </IconButton>
                            </label>
                        </MenuItem>
                        {(isActive('showDisableSignOption') || user.profile === "admin") && (
                            <MenuItem onClick={handleMenuItemClick}>
                                <FormControlLabel
                                    label={i18n.t("messagesInput.signMessage")}
                                    labelPlacement="start"
                                    className={classes.formControl}
                                    control={
                                        <Switch
                                            size="small"
                                            checked={signMessage}
                                            onChange={e => {
                                                setSignMessage(e.target.checked);
                                            }}
                                            name="showAllTickets"
                                            color="primary"
                                        />
                                    }
                                />
                            </MenuItem>
                        )}
                    </Menu>
                </Hidden>
                <Can
                    role={user.roleId}
                    perform="messages:send:any"
                    yes={() => (
                        <>
                            <div className={classes.messageInputWrapper}>
                                <InputBase
                                    inputRef={input => {
                                        input && input.focus();
                                        input && (inputRef.current = input);
                                    }}
                                    className={classes.messageInput}
                                    placeholder={
                                        ticketStatus === "open" || ticketStatus === "group"
                                            ? i18n.t("messagesInput.placeholderOpen")
                                            : i18n.t("messagesInput.placeholderClosed")
                                    }
                                    multiline
                                    maxRows={5}
                                    value={inputMessage}
                                    onChange={handleChangeInput}
                                    disabled={recording || loading || (ticketStatus !== "open" && ticketStatus !== "group")}
                                    onPaste={e => {
                                        (ticketStatus === "open" || ticketStatus === "group") && handleInputPaste(e);
                                    }}
                                    onKeyDown={handleShortcutKeyPressStart}
                                    onKeyPress={e => {
                                        if (loading || e.shiftKey) return;
                                        else if (!blockEnter && e.key === "Enter") {
                                            editingMessage ? handleSendEditedMessage() : handleSendMessage();
                                        }
                                        if (blockEnter) {
                                            setBlockEnter(false);
                                        }
                                    }}
                                />
                                {typeBar ? (
                                    <ul className={classes.messageShortcutWrapper} ref={shortcutMessagesListRef} onKeyDown={handleShortcutKeyPress}>
                                        {suggestions.map((value, index) => {
                                            return (<li className={classes.messageShortcutWrapperItem} tabIndex={0} key={index}>
                                                <a onClick={(event) => { event.preventDefault(); handleSuggestionClick(value) }} href="#">
                                                    {value.mediaUrl && '[Arquivo] '}{value.name}
                                                </a>
                                            </li>)
                                        })}
                                    </ul>
                                ) : (<div></div>)}
                            </div>
                            {inputMessage || medias.length > 0 ? (
                                <IconButton
                                    aria-label="sendMessage"
                                    component="span"
                                    onClick={editingMessage ? handleSendEditedMessage : handleSendMessage}
                                    disabled={loading}
                                    size="large">
                                    <SendIcon className={classes.sendMessageIcons} />
                                </IconButton>
                            ) : recording ? (
                                <div className={classes.recorderWrapper}>
                                    <IconButton
                                        aria-label="cancelRecording"
                                        component="span"
                                        fontSize="large"
                                        disabled={loading}
                                        onClick={handleCancelAudio}
                                        size="large">
                                        <HighlightOffIcon className={classes.cancelAudioIcon} />
                                    </IconButton>
                                    {loading ? (
                                        <div>
                                            <CircularProgress className={classes.audioLoading} />
                                        </div>
                                    ) : (
                                        <RecordingTimer />
                                    )}

                                    <IconButton
                                        aria-label="sendRecordedAudio"
                                        component="span"
                                        onClick={handleUploadAudio}
                                        disabled={loading}
                                        size="large">
                                        <CheckCircleOutlineIcon className={classes.sendAudioIcon} />
                                    </IconButton>
                                </div>
                            ) : (
                                <IconButton
                                    aria-label="showRecorder"
                                    component="span"
                                    disabled={loading || (ticketStatus !== "open" && ticketStatus !== "group")}
                                    onClick={handleStartRecording}
                                    size="large">
                                    <MicIcon className={classes.sendMessageIcons} />
                                </IconButton>
                            )}
                        </>
                    )}
                    no={() => <></>}
                />
                {keyOpenAi || user.roleId === 1 ?
                    <Can
                        role={user.roleId}
                        perform="messages:promptai"
                        yes={() => (
                            <IconButton
                                component="span"
                                onClick={() => setPromptAIModalOpen(true)}
                                size="large">
                                <AutoAwesomeIcon className={classes.sendMessageIcons} />
                            </IconButton>)}
                        no={() => <></>}
                    /> : <></>
                }
                <Can
                    role={user.roleId}
                    perform="shortcut-messages:create"
                    yes={() => (
                        <IconButton
                            component="span"
                            onClick={() => setAppointmentModalOpen(true)}
                            size="large">
                            <Timer className={classes.sendMessageIcons} />
                        </IconButton>)}
                    no={() => <></>}
                />
            </div >
            {medias.length > 0 && (
                <div className={classes.carouselContainer}>
                    {medias.map((media, index) => {
                        let backgroundImage = '';
                        if (media.source == 'suggestion') {
                            backgroundImage = `url(${process.env.REACT_APP_BACKEND_URL}/public/app/${encodeURIComponent(media.name)})`;
                        } else if (media.source == 'direct') {
                            if (media.preview) {
                                backgroundImage = `url(${media.preview})`;
                            } else if (media.name.endsWith('.pdf')) {
                                backgroundImage = `url(/icons/pdf.png)`;
                            } else if (media.name.endsWith('.xlsx') || media.name.endsWith('.xlsm')) {
                                backgroundImage = `url(/icons/xls.png)`;
                            } else if (media.name.match(/\.(doc|docx|zip|html|exe|)$/)) {
                                backgroundImage = `url(/icons/doc.png)`;
                            }
                        }
                        return (
                            <div className={classes.carouselItemUp}>
                                <div key={index} className={classes.carouselItem} style={{ backgroundImage }}>
                                    <CancelIcon className={classes.removeIcon} onClick={() => {
                                        const newMedias = [...medias];
                                        newMedias.splice(index, 1);
                                        setCaption("");
                                        setMedias(newMedias);
                                    }} />
                                </div>
                                <span className={classes.carouselName}>{media.name}</span>
                            </div>
                        );
                    })}
                </div>
            )}
        </Paper>
    );
};

export default MessageInput;
