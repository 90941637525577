import React, { useState, useContext } from "react";

import * as Yup from "yup";
import { Formik, Form } from "formik";
import makeStyles from '@mui/styles/makeStyles';
import { green } from "@mui/material/colors";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import { SettingsContext } from "../../context/Settings/SettingsContext";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import LoadingBox from "../LoadingBox";
import { toast } from "react-toastify";


const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap"
	},
	answerLoading: {
		minHeight: 200,
		maxHeight: 300,
		width: 300,
		margin: 20,
		borderRadius: 10,
		background: theme.palette.blackWhiteBackground,
		display: 'flex',
		alignContent: 'center',
		alignItems: 'center',
		justifyContent: 'center'
	},
	answerBlock: {
		fontSize: 16,
		lineHeight: '27px',
		padding: 20,
		background: theme.palette.blackWhiteBackground,
		borderRadius: 10,
		margin: 20,
		minHeight: 200,
		maxHeight: 300,
		overflow: 'auto'
	},

	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
	},

	btnWrapper: {
		position: "relative",
	},
	circleLoading: {
		color: green[500],
		opacity: "70%"
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	colorAdorment: {
		width: 20,
		height: 20,
	},
	messageSaved: {
		boxShadow: "0 1px 1px #b3b3b3",
		background: "#f5f5f5",
		padding: '5px 15px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	cont: {
		fontStyle: "italic",
	},
	timestamp: {
		fontStyle: 'normal',
		fontSize: 11,
		color: '#666666'
	}
}));



const QueueSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Too Short!")
		.max(50, "Too Long!")
		.required("Required"),
	color: Yup.string().min(3, "Too Short!").max(9, "Too Long!").required(),
	greetingMessage: Yup.string(),
});

const PromptAudiotoTextModal = ({ open, onClose, message, handleAIMessage }) => {
	const classes = useStyles();
	const [answer, setAnswer] = useState('');
	const paragraphs = answer && answer.split('\n');
	const {  getSettingValue } = useContext(SettingsContext);
	const apiKey = getSettingValue('keyOpenAi');
	const [loading, setLoading] = useState(false);
	const initialState = {
		name: "",
		color: "",
		greetingMessage: "",
	};

	const [queue, setQueue] = useState(initialState);


	const handleClose = () => {
		onClose();
		setQueue(initialState);
		setAnswer('');
	};



	const handleAI = async values => {
		//const originalUrl = message.rawMediaUrl;
		//const updatedUrl = originalUrl.replace(".ogg", ".mp3");
		try {
			let data = {
				audioUrl: message.rawMediaUrl,
				apikey: apiKey
			}	
			setLoading(true);
			const response = await api.post("/ai/transcript", data);
			if (response.data && response.data.text !== undefined) {
			
				setAnswer(response.data.text);
			
			} else {
			
				console.error("Invalid response data:", response.data);
			}
			setLoading(false);
		} catch (err) {
			setLoading(false);		
			toast.error(i18n.t("backendErrors.ERR_OPENAI"));
		}
	};


	return (
		<div className={classes.root}>
			<Dialog open={open} onClose={handleClose} scroll="paper">
				<DialogTitle>
					{i18n.t("speechtoTextAiModal.title.add")}
				</DialogTitle>

				{loading ? 
					<LoadingBox /> : (
					answer ? (
						<div className={classes.answerBlock}>
							{paragraphs.map((paragraph, index) => (
								<p  className={classes.answer} key={index}>{paragraph}</p>
							))}
						</div>
					) : (
						<Formik
							initialValues={queue}
							enableReinitialize={true}
							validationSchema={QueueSchema}
							onSubmit={(values, actions) => {
								setTimeout(() => {
									handleAI(values);
									actions.setSubmitting(false);
								}, 400);
							}}
						>
							{({ touched, errors, isSubmitting, values }) => (
								<Form>
									<DialogContent dividers>


										<div>
											{message.mediaUrl && (
												<><label className={classes.label}>Mensagem</label>
													<div className={classes.messageSaved}>
														<audio controls>
															<source src={`${message.mediaUrl}`} type="audio/mp3"></source>
														</audio>
													</div></>)}
										</div>
									</DialogContent>
									<DialogActions>
										<Button
											onClick={handleClose}
											color="secondary"
											disabled={isSubmitting}
											variant="outlined"
										>
											{i18n.t("speechtoTextAiModal.buttons.cancel")}
										</Button>



										<Button
											type="submit"
											color="primary"
											disabled={isSubmitting}
											variant="contained"
											className={classes.btnWrapper}
											onClick={() => handleAI(values)}
										>

											{i18n.t("speechtoTextAiModal.buttons.okSearch")}
											{isSubmitting && (
												<CircularProgress
													size={24}
													className={classes.buttonProgress}
												/>
											)}
										</Button>
									</DialogActions>
								</Form>
							)}
						</Formik>
					)
				)}

				{answer && (
					<DialogActions>
						<Button
							onClick={handleClose}
							color="secondary"
							variant="outlined"
						>
							{i18n.t("speechtoTextAiModal.buttons.close")}
						</Button>

					</DialogActions>
				)}
			</Dialog>
		</div>
	);
};

export default PromptAudiotoTextModal