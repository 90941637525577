import React, { useState, useContext, useEffect } from "react";
import makeStyles from '@mui/styles/makeStyles';

import {
    CssBaseline,
    TextField,
    Toolbar,
    List,
    Typography,
    Divider,
    MenuItem,
    Button,
    Container,
    IconButton,
    Menu,
    useTheme,
} from "@mui/material";

import Version from "../../helpers/version";
import NightlightRoundIcon from '@mui/icons-material/NightlightRound';
import LightModeIcon from '@mui/icons-material/LightMode';
import { LanguageSelector, i18n } from "../../translate/i18n";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { AuthContext } from "../../context/Auth/AuthContext";
import ColorModeContext from "../../layout/themeContext";
import api from "../../services/api";
import { IoSchoolOutline } from "react-icons/io5";
import { useHistory } from "react-router-dom";
//const backgroundLoginURL = `${process.env.REACT_APP_BACKEND_URL}/background-login`;

const backgroundLoginURL = `/background.svg`;

const useStyles = makeStyles(theme => ({
    "@global": {
        body: {
            background: `url('${backgroundLoginURL}')`,
            backgroundSize: "cover",
            [theme.breakpoints.down('sm')]: {
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'auto',
                backgroundPosition: 'inherit',
                backgroundAttachment: 'fixed'

            }
        }
    },
    colFull: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        },
    },
    colFullHeader: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    qrcode: {
        width: '50%',
        textAlign: "center"
    },
    paper: {
        alignItems: "center",
        background: theme.palette.lightWhite.main,
        borderRadius: 20,
        boxShadow: '0px 4px 25px 0px rgba(0,0, 0, 0.05)',
        padding: 30,
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 780,
        [theme.breakpoints.down('sm')]: {
            width: '90%'
        }
    },
    avatar: {
        height: 50
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),       
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    title: {
        textAlign: "center",
        marginTop: "5%",

    },
    versao: {
        fontSize: "15px",
        textAlign: "center",
        width: '100%',
        padding: '15px 0px'
    },
    class: {
        fontSize: "16px",
        fontWeight: "bold",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: 5,
        width: '100%',
        padding: '15px 0px',
        cursor: 'pointer'
    },
    notificationWarning: {
        color: "red",
    },
    notificationModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 700,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            textAlign: 'center',
        },
        '& img': {
            height: '700px',
            [theme.breakpoints.down('sm')]: {
                height: 'auto',
                width: '90%'
            }
        }
    },
    notificationModalClose: {
        position: 'absolute',
        right: 0,
        top: 0,
        color: '#fff',
        "& .MuiSvgIcon-root": {
            fontSize: "3rem"
        }
    },
    qrcode: {
        width: "100%",
        textAlign: "center",
        marginBottom: 20
    },
    qrcodeImage: {
        width: 130,
    },
    playStoreButton: {
        height: 50
    },
    h3App: {
        fontWeight: 600,
        fontSize: 16
    },
    buttonsActionLogin: {
        display: 'flex',
        flexDirection: 'row'
    }
}));


const Login = () => {
    const classes = useStyles();
    const theme = useTheme();
    const apiURL = process.env.REACT_APP_BACKEND_URL;

    const [user, setUser] = useState({ email: "", password: "" });
    const [isFromFlutter, setIsFromFlutter] = useState(false);
    const [pushToken, setPushToken] = useState("");
    const [showQRCode, setShowQRCode] = useState(false);
    const { colorMode } = useContext(ColorModeContext);
    const history = useHistory();
    const appUserAgent = "Onecode Jupiter App 1.0";
    const fromApp = navigator.userAgent === appUserAgent;

    const { handleLogin } = useContext(AuthContext);
    const [showPassword, setShowPassword] = useState(false);

    const handleChangeInput = e => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    const checkPlayStoreUrl = async () => {
        const { data } = await api.get("/have-playstore-url");
        if (data.success) {
            setShowQRCode(true);
        }
    };

    useEffect(() => {
        checkPlayStoreUrl();
    }, []);

    const handlSubmit = e => {
        e.preventDefault();
        const sentData = user;
        if (window.localStorage.getItem("pushToken")) {
            sentData.pushToken = window.localStorage.getItem("pushToken");
        }
        handleLogin(sentData);
    };

    window.addEventListener("flutterInAppWebViewPlatformReady", function (event) {
        setIsFromFlutter(true);
        window.flutter_inappwebview.callHandler('getPushToken').then((data) => {
            setPushToken(data.token);
            window.localStorage.setItem("pushToken", data.token);
        });
    });

    const toggleColorMode = () => {
        colorMode.toggleColorMode();
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <div className={classes.colFullHeader} style={{ marginBottom: 10 }}>
                    <img className={classes.avatar} src="/logo-black.png" alt="OneCode Logo" style={{maxHeight: 60}}/>
                    <div className={classes.buttonsActionLogin}>
                        <IconButton edge="start" onClick={toggleColorMode} color="inherit" size="large">
                            {theme.mode === 'dark' ? <LightModeIcon /> : <NightlightRoundIcon />}
                        </IconButton>
                        <LanguageSelector />
                    </div>
                </div>

                <div className={classes.colFull}>

                    <div className={classes.formLogin}>

                        <form className={classes.form} noValidate onSubmit={handlSubmit}
                        style={!showQRCode ? {margin: '0 auto', maxWidth: 320} : {}}
                        >

                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label={i18n.t("login.form.email")}
                                name="email"
                                value={user.email}
                                onChange={handleChangeInput}
                                autoComplete="email"
                                type="email"
                                autoFocus
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label={i18n.t("login.form.password")}
                                type={showPassword ? "text" : "password"}
                                id="password"
                                value={user.password}
                                onChange={handleChangeInput}
                                autoComplete="current-password"
                                InputProps={{
                                    endAdornment: (
                                        <IconButton
                                            aria-label={showPassword ? "Hide password" : "Show password"}
                                            onClick={() => setShowPassword(!showPassword)}
                                            edge="end"
                                        >
                                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                                        </IconButton>
                                    ),
                                }}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                            >
                                {i18n.t("login.buttons.submit")}
                            </Button>
                        </form>
                    </div>
                    {showQRCode ?
                    <div className={classes.qrcode}> 
                            <div>
                                <h3 className={classes.h3App}>{i18n.t("login.download")}</h3>
                                <img src={`${apiURL}/qrcode-playstore`} alt="qrcode" className={classes.qrcodeImage} />
                                <p>
                                    <a href={`${apiURL}/playstore`} target="_blank" rel="noopener noreferrer">
                                        <img alt='Disponível no Google Play' src='https://play.google.com/intl/pt-BR/badges/static/images/badges/pt-br_badge_web_generic.png' className={classes.playStoreButton} />
                                    </a>
                                </p>
                            </div>                          
                    </div>
                      : <></>}
                </div>

                <div className={classes.colFull}>
                    <Typography className={classes.versao}>
                        Versão: {Version} {fromApp ? <span>. Flutter: {isFromFlutter ? <span>Sim. Push Token: {pushToken.substring(0, 6)}...</span> : ""}</span> : ""}
                        {fromApp && (!isFromFlutter || !pushToken) ? <p className={classes.notificationWarning}>Houve um problema ao inicializar a aplicação. Você não receberá notificações das mensagens nesse dispositivo. Tente reiniciar a aplicação.</p> : ""}
                    </Typography>
                </div>

                <div className={classes.colFull}>
                    <Typography className={classes.class} onClick={() => history.push('/help')}>
                    <IoSchoolOutline size={28} color="#48dbfb" /> Aprenda como utilizar a plataforma
                    </Typography>
                </div>
            </div>
        </Container>
    );
};

export default Login;