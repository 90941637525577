import React, { useState, useContext } from "react";

import * as Yup from "yup";
import { Formik, Form } from "formik";

import makeStyles from '@mui/styles/makeStyles';
import { green } from "@mui/material/colors";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import { SettingsContext } from "../../context/Settings/SettingsContext";
import { i18n } from "../../translate/i18n";
import Select from 'react-select';

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { ArrowRight } from "@mui/icons-material";
import LoadingBox from "../LoadingBox";
import { toast } from "react-toastify";
import PromptTextToSpeechModal from "../PromptTexttoSpeechModal";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap"
	},
	answerLoading: {
		minHeight: 200,
		maxHeight: 300,
		width: 300,
		margin: 20,
		borderRadius: 10,
		background: theme.palette.blackWhiteBackground,
		display: 'flex',
		alignContent: 'center',
		alignItems: 'center',
		justifyContent: 'center'
	},
	answerBlock: {
		fontSize: 16,
		lineHeight: '27px',
		padding: 20,
		background: theme.palette.fancyBackground,
		borderRadius: 10,
		//margin: 20,
		minHeight: 200,
		maxHeight: 300,
		overflow: 'auto'
	},

	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
	},

	btnWrapper: {
		position: "relative",
	},
	circleLoading: {
		color: green[500],
		opacity: "70%"
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	colorAdorment: {
		width: 20,
		height: 20,
	},
	messageSaved: {
		boxShadow: "0 1px 1px #b3b3b3",
		background: theme.palette.blackWhiteBackground,
		padding: '5px 15px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	cont: {
		fontStyle: "italic",
	},
	timestamp: {
		fontStyle: 'normal',
		fontSize: 11,
		color: '#666666'
	}
}));


const customStyles = {
	control: (provided) => ({
		...provided,
		width: 200,
	}),
};

const QueueSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Too Short!")
		.max(50, "Too Long!")
		.required("Required"),
	color: Yup.string().min(3, "Too Short!").max(9, "Too Long!").required(),
	greetingMessage: Yup.string(),
});

const PromptTranslateModal = ({ open, onClose, message, handleAIMessage }) => {
	const classes = useStyles();
	const [answer, setAnswer] = useState('');
	const { getSettingValue } = useContext(SettingsContext);
	const apiKey = getSettingValue('keyOpenAi');
	const [loading, setLoading] = useState(false);
	const initialState = {
		name: "",
		color: "",
		greetingMessage: "",
	};

	const [queue, setQueue] = useState(initialState);
	const paragraphs = answer && answer.split('\n');
	const timestamp = new Date(message.createdAt);
	const formattedTime = timestamp.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
	const formattedDate = timestamp.toLocaleDateString('en-GB', {
		day: '2-digit',
		month: '2-digit',
		year: 'numeric',
	});
	const formattedDateTime = `${formattedDate} - ${formattedTime}`;
	const languageOptions = [
		{ value: 'en', label: 'English' },
		{ value: 'es', label: 'Spanish' },
		{ value: 'pt-BR', label: 'Portuguese (Brazil)' },
		{ value: 'fr', label: 'French' },
		{ value: 'de', label: 'German' },
		{ value: 'it', label: 'Italian' },
		{ value: 'nl', label: 'Dutch' },
		{ value: 'ru', label: 'Russian' },
		{ value: 'zh-CN', label: 'Chinese (Simplified)' },
		{ value: 'ja', label: 'Japanese' },
		{ value: 'ko', label: 'Korean' },
		{ value: 'ar', label: 'Arabic' },
		{ value: 'tr', label: 'Turkish' },
		{ value: 'hi', label: 'Hindi' },
		{ value: 'sv', label: 'Swedish' },
		{ value: 'pl', label: 'Polish' },
		{ value: 'uk', label: 'Ukrainian' },
		{ value: 'el', label: 'Greek' },
		{ value: 'da', label: 'Danish' },
		{ value: 'no', label: 'Norwegian' },
	];

	const [selectedLanguageFrom, setSelectedLanguageFrom] = useState('pt-BR');
	const [selectedLanguageTo, setSelectedLanguageTo] = useState('pt-BR');

	const handleLanguageFromChange = selectedOption => {
		setSelectedLanguageFrom(selectedOption.value);
	};

	const handleLanguageToChange = selectedOption => {
		setSelectedLanguageTo(selectedOption.value);
	};

	const handleClose = () => {
		onClose();
		setQueue(initialState);
		setAnswer('');
	};

	const handleAI = async values => {
		try {
			let data = {
				lang_from: selectedLanguageFrom,
				lang_to: selectedLanguageTo,
				text: message.body,
				apikey: apiKey
			}
			setLoading(true);
			const response = await api.post("/ai/translate", data);
			if (response) {
				//console.log(response.data);
				setAnswer(response.data.choices[0].message.content)
			}
			setLoading(false);
		} catch (err) {
			setLoading(false);
			toast.error(i18n.t("backendErrors.ERR_OPENAI"));
		}
	};

	return (
		<div className={classes.root}>
			<Dialog open={open} onClose={handleClose} scroll="paper">
				<DialogTitle>
					{i18n.t("translateModal.title.add")}
				</DialogTitle>

				{loading ?
					<LoadingBox /> : (
						answer ? (
							<div className={classes.answerBlock}>
								<PromptTextToSpeechModal
									open={false}
									aria-labelledby="form-dialog-title"
									message={{ body: answer }}
									icon={true}
								/>
								{paragraphs.map((paragraph, index) => (
									<p className={classes.answer} key={index}>{paragraph}</p>
								))}
							</div>
						) : (
							<Formik
								initialValues={queue}
								enableReinitialize={true}
								validationSchema={QueueSchema}
								onSubmit={(values, actions) => {
									setTimeout(() => {
										handleAI(values);
										actions.setSubmitting(false);
									}, 400);
								}}
							>
								{({ touched, errors, isSubmitting, values }) => (
									<Form>
										<DialogContent dividers>

											<div
												style={{
													display: 'flex', justifyContent: 'space-between', alignItems: 'center',
													margin: '15px 0 100px 0px'
												}}>
												<Select
													options={languageOptions}
													value={languageOptions.find(option => option.value === selectedLanguageFrom)}
													onChange={handleLanguageFromChange}
													styles={customStyles}
												/>
												<ArrowRight />
												<Select
													styles={customStyles}
													onChange={handleLanguageToChange}
													options={languageOptions} />
											</div>
											<div>
												{message && (
													<><label className={classes.label}>Mensagem</label>
														<div className={classes.messageSaved}>
															<span className={classes.cont}>{message.body}</span>
															<span className={classes.timestamp}>{formattedDateTime}</span>
														</div></>)}
											</div>
										</DialogContent>
										<DialogActions>
											<Button
												onClick={handleClose}
												color="secondary"
												disabled={isSubmitting}
												variant="outlined"
											>
												{i18n.t("translateModal.buttons.cancel")}
											</Button>



											<Button
												type="submit"
												color="primary"
												disabled={isSubmitting}
												variant="contained"
												className={classes.btnWrapper}
												onClick={() => handleAI(values)}
											>

												{i18n.t("translateModal.buttons.okSearch")}
												{isSubmitting && (
													<CircularProgress
														size={24}
														className={classes.buttonProgress}
													/>
												)}
											</Button>
										</DialogActions>
									</Form>
								)}
							</Formik>
						)
					)}

				{answer && (
					<DialogActions>
						<Button
							onClick={handleClose}
							color="secondary"
							variant="outlined"
						>
							{i18n.t("translateModal.buttons.close")}
						</Button>

					</DialogActions>
				)}
			</Dialog>
		</div>
	);
};

export default PromptTranslateModal