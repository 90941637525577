import React, { useState, useContext, useRef } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import makeStyles from '@mui/styles/makeStyles';
import { green } from "@mui/material/colors";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import { SettingsContext } from "../../context/Settings/SettingsContext";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import LoadingBox from "../LoadingBox";
import { toast } from "react-toastify";
import PromptTextToSpeechModal from "../PromptTexttoSpeechModal";


const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap"
	},
	answerLoading: {
		minHeight: 200,
		maxHeight: 300,
		width: 300,
		margin: 20,
		borderRadius: 10,
		background: theme.palette.blackWhiteBackground,
		display: 'flex',
		alignContent: 'center',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center'
	},
	answerBlock: {
		fontSize: 16,
		lineHeight: '27px',
		padding: 20,
		background: theme.palette.fancyBackground,
		borderRadius: 10,
		//margin: 20,
		minHeight: 200,
		maxHeight: 300,
		overflow: 'auto'
	},

	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
	},

	btnWrapper: {
		position: "relative",
	},
	circleLoading: {
		color: green[500],
		opacity: "70%"
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	colorAdorment: {
		width: 20,
		height: 20,
	},
	messageSaved: {
		boxShadow: "0 1px 1px #b3b3b3",
		background: theme.palette.blackWhiteBackground,
		padding: '5px 15px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	cont: {
		fontStyle: "italic",
	},
	timestamp: {
		fontStyle: 'normal',
		fontSize: 11,
		color: '#666666'
	}
}));


const PromptSchema = Yup.object().shape({
	question: Yup.string()
		.min(2, "Too Short!")
		.max(50, "Too Long!")
		.required("Required")
});

const PromptSearchModal = ({ open, onClose, message, handleAIMessage }) => {
	const classes = useStyles();
	const [answer, setAnswer] = useState('');
	const { getSettingValue } = useContext(SettingsContext);
	const apiKey = getSettingValue('keyOpenAi');
	const [loading, setLoading] = useState(false);
	const initialState = {
		question: i18n.t("searchModal.form.prompt"),
	};

	const [prompt, setPrompt] = useState(initialState);
	const paragraphs = answer && answer.split('\n');
	const greetingRef = useRef();



	const handleClose = () => {
		onClose();
		setPrompt(initialState);
		setAnswer('');
	};


	const handleAI = async values => {
		try {
			let data = {
				question: values.question ? values.question : i18n.t("searchModal.form.prompt"),
				text: message.body,
				apikey: apiKey
			}

			setLoading(true);
			const response = await api.post("/ai/search", data);
			if (response) {
				setAnswer(response.data.choices[0].message.content)
			}
			setLoading(false);
		} catch (err) {
			setLoading(false);
			toast.error(i18n.t("backendErrors.ERR_OPENAI"));
		}
	};

	return (
		<div className={classes.root}>
			<Dialog open={open} onClose={handleClose} scroll="paper">
				<DialogTitle>
					{i18n.t("searchModal.title.add")}
				</DialogTitle>

				{loading ?
					<LoadingBox /> : (
						answer ? (
							<div className={classes.answerBlock}>
								<PromptTextToSpeechModal
									open={false}
									aria-labelledby="form-dialog-title"
									message={{ body: answer }}
									icon={true}
								/>
								{paragraphs.map((paragraph, index) => (
									<p className={classes.answer} key={index}>{paragraph}</p>
								))}
							</div>
						) : (
							<Formik
								initialValues={prompt}
								enableReinitialize={true}
								validationSchema={PromptSchema}
								onSubmit={(values, actions) => {
									setTimeout(() => {
										handleAI(values);
										actions.setSubmitting(false);
									}, 400);
								}}
							>
								{({ touched, errors, isSubmitting, values }) => (
									<Form>
										<DialogContent dividers>

											<div
												style={{
													display: 'flex', justifyContent: 'space-between', alignItems: 'center',
													margin: '15px 0 15px 0px'
												}}>
												<Field
													as={TextField}
													label={i18n.t("searchModal.form.label")}
													type="prompt"
													multiline
													inputRef={greetingRef}
													rows={3}
													fullWidth
													name="question"
													error={
														touched.question && Boolean(errors.question)
													}
													helperText={
														touched.question && errors.question
													}
													variant="outlined"
													margin="dense"
												/>
											</div>
											<div>
												{message && (
													<><label className={classes.label}>Sobre esta mensagem</label>
														<div className={classes.messageSaved}>
															<span className={classes.cont}>{message.body}</span>

														</div></>)}
											</div>
										</DialogContent>
										<DialogActions>
											<Button
												onClick={handleClose}
												color="secondary"
												disabled={isSubmitting}
												variant="outlined"
											>
												{i18n.t("searchModal.buttons.cancel")}
											</Button>

											<Button
												type="submit"
												color="primary"
												disabled={isSubmitting}
												variant="contained"
												className={classes.btnWrapper}
												onClick={() => handleAI(values)}
											>

												{i18n.t("searchModal.buttons.okSearch")}
												{isSubmitting && (
													<CircularProgress
														size={24}
														className={classes.buttonProgress}
													/>
												)}
											</Button>
										</DialogActions>
									</Form>
								)}
							</Formik>
						)
					)}

				{answer && (
					<DialogActions>
						<Button
							onClick={handleClose}
							color="secondary"
							variant="outlined"
						>
							{i18n.t("translateModal.buttons.close")}
						</Button>

					</DialogActions>
				)}
			</Dialog>
		</div>
	);
};

export default PromptSearchModal