import React, { useContext, useState } from "react";
import { Button, CircularProgress, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles"
import { Check, Close, GetApp, Lock } from "@mui/icons-material";
import { SettingsContext } from "../../context/Settings/SettingsContext";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { downloadResource } from "../../utils";
import Swal from "sweetalert2";
import { Document, Page } from 'react-pdf';
import { FileIcon, defaultStyles } from "react-file-icon";

const documentWidth = 400;

const useStyles = makeStyles({
    documentMessage: {

    },
    documentFilename: {
        display: "flex",
        background: "rgba(0, 0, 0, 0.1)",
        alignItems: "center",
        padding: 10
    },
    documentPreviewContainer: {
        height: 100,
        overflow: "hidden",
        width: documentWidth,
    },

    fileIconContainer: {
        width: 32,
        marginRight: 10
    },
    fileNameContainer: {
        flexGrow: 1
    }
});

/**
 * Format bytes as human-readable text.
 * 
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use 
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 * 
 * @return Formatted string.
 */
const humanFileSize = (bytes, si = false, dp = 1) => {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }

    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


    return bytes.toFixed(dp) + ' ' + units[u];
}

const MessageDocument = ({ message }) => {
    const classes = useStyles();

    const [downloading, setDownloading] = useState(false);
    const [checkFileEnabled, setCheckFileEnabled] = useState(false);
    const [numPages, setNumPages] = useState();
    const [hasPassword, setHasPassword] = useState(false);

    const { isActive } = useContext(SettingsContext);

    const extension = message.mediaUrl.split('.').pop();

    const handleFileAnalysis = async (message) => {
        if (message.fileAnalysisId && message.fileAnalysisResultSimple) {
            if (message.fileAnalysisResultSimple === "harmless" || message.fileAnalysisResultSimple === "undetected") {
                handleDownloadMedia(message.mediaUrl);
            }
        } else {
            try {
                if (!message.fileAnalysisId || !message.fileAnalysisResultSimple) {
                    await api.get(`/messages/${message.id}/check`);
                } else {
                    toastError("A verificação de vírus já foi iniciada para esse arquivo");
                }
            } catch (err) {
                toastError(err);
            }
        }
    }

    const renderLabelFileAnalysis = (message) => {
        if (message.fileAnalysisResultSimple === "malicious") {
            return <span style={{ color: "red" }}><Close /> Arquivo malicioso</span>;
        }
        if (message.fileAnalysisResultSimple === "suspicious") {
            return <span style={{ color: "orange" }}><Close /> Arquivo suspeito</span>;
        }
        if (message.fileAnalysisResultSimple === "harmless" || message.fileAnalysisResultSimple === "undetected") {
            return <span style={{ color: "green" }}><Check /> Nenhuma ameaça encontrada (clique para fazer o download)</span>;
        }
        return <span>Não foi possível verificar o arquivo por vírus</span>
    }

    const renderFileAnalysis = (message) => {
        return <Button
            color="primary"
            onClick={() => handleFileAnalysis(message)}
            disabled={["malicious", "suspicious"].indexOf(message.fileAnalysisResultSimple) > -1}
        >
            {!message.fileAnalysisId && "Verificar vírus no arquivo"}
            {message.fileAnalysisId && !message.fileAnalysisResultSimple && "Verificando vírus. Aguarde..."}
            {message.fileAnalysisId && message.fileAnalysisResultSimple && renderLabelFileAnalysis(message)}
        </Button>
    }

    const handleDownloadMedia = (mediaUrl) => {
        setDownloading(mediaUrl);
        if (isActive('showDownloadConfirmation')) {
            Swal.fire({
                title: 'Deseja fazer o download desse arquivo?',
                text: "Tem certeza que deseja baixar o arquivo para seu PC?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim!'
            }).then((result) => {
                if (result.isConfirmed) {
                    if (isActive('forceDownload')) {
                        downloadResource(mediaUrl);
                    } else {
                        window.open(mediaUrl, "_blank");
                    }
                }
                setDownloading(null);
            });
        } else {
            downloadResource(mediaUrl);
            setDownloading(null);
        }
    }

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    }

    const onDocumentPassword = () => {
        setHasPassword(true);
    }

    return (
        <div className={classes.documentMessage}>
            {message.mediaType === 'document' && (extension === 'pdf' || message.fileMimeType === 'application/pdf') && !hasPassword && <div className={classes.documentPreviewContainer}>
                <Document file={message.mediaUrl} onLoadSuccess={onDocumentLoadSuccess} onPassword={onDocumentPassword}>
                    <Page width={documentWidth} pageNumber={1} renderTextLayer={false} renderAnnotationLayer={false} />
                </Document>
            </div>}
            <div className={classes.documentFilename}>
                <div className={classes.fileIconContainer}>
                    <FileIcon extension={extension} {...defaultStyles[extension]} />
                </div>
                <div className={classes.fileNameContainer}>
                    {message.rawMediaUrl}
                    <div>
                        {message.documentPageCount || numPages ? <span>{message.documentPageCount || numPages} páginas</span> : <></>}
                        {message.documentPageCount && message.fileSize ? <span> • </span> : <></>}
                        {hasPassword && <Lock />} {message.fileSize && <span>{humanFileSize(message.fileSize, true)}</span>}
                    </div>
                </div>
                {downloading == message.mediaUrl ? <CircularProgress color="inherit" /> :
                    checkFileEnabled ? renderFileAnalysis(message) :
                        isActive('showDownloadConfirmation') || isActive('forceDownload') ?
                            <IconButton
                                color="primary"
                                variant="outlined"
                                target="_blank"
                                onClick={() => handleDownloadMedia(message.mediaUrl)}
                            >
                                <GetApp />
                            </IconButton> :
                            <IconButton
                                color="primary"
                                variant="outlined"
                                target="_blank"
                                href={message.mediaUrl}
                            >
                                <GetApp />
                            </IconButton>}
            </div>
        </div>);
}

export default MessageDocument;