import React, { useState, useEffect } from "react";
import { ResponsiveBar } from "@nivo/bar";
import moment from "moment";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { Button, useTheme, Tooltip, IconButton } from "@mui/material";
import { FaInfoCircle } from "react-icons/fa";

const ChartDailyTicketsComponent = ({ period, userIds, contactIds, statuses, queueIds, tagIds, endpoint, title, yAxisTitle, colorChart, setLoading, theme, subtitle }) => {
  const [chartData, setChartData] = useState(null);
  const [internalLoading, setInternalLoading] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [titleAxis, setYtitle] = useState('');
  const [allNames, setAllNames] = useState([]);
  const themeApp = useTheme();

  const transformData = (data) => {
    console.log('received data tickets per day', data);
    const filteredData = data.filter(ticket => ticket.day !== "Total");
    const transformedData = filteredData.map(ticket => ({
      date: ticket.day.split('-').reverse().join('-'),
      Total: ticket.value,
      TotalColor: "hsl(331, 70%, 50%)",
    }));
    console.log('was transformed tickets per day:', transformedData);
    setChartData(transformedData);

    return transformedData;
  };

  useEffect(() => {
    let isMounted = true;
    setYtitle(yAxisTitle);
    const fetchData = async () => {
      try {
        setInternalLoading(true);
        const response = await api.get(endpoint, {
          params: {
            dateStart: moment(period[0]).format("YYYY-MM-DD"),
            dateEnd: moment(period[1]).format("YYYY-MM-DD"),
            contactIds: JSON.stringify(contactIds),
            userIds: JSON.stringify(userIds),
            queueIds: JSON.stringify(queueIds),
            statuses: JSON.stringify(statuses),
            tagIds: JSON.stringify(tagIds),
          },
        });

        if (response.data && isMounted) {

          setChartData(response.data.data);
          setInternalLoading(false);
          setLoading(false);
          transformData(response.data.data);
        }
      } catch (err) {
        if (isMounted) {
          setInternalLoading(false);
          setLoading(false);
          toastError(err);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [period, userIds, contactIds, queueIds, tagIds, statuses, endpoint, colorChart]);

  return (
    <>
      <h2 style={{ fontSize: 20, margin: '0px 15px' }}>
        <div style={{ display: 'flex', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
            {title}
            <Tooltip title={`Este gráfico exibe o número de atendimentos realizados em cada dia`}>
              <IconButton>
                <FaInfoCircle size={15} />
              </IconButton>
            </Tooltip>
          </span>
        </div>
      </h2>

      {chartData && chartData.length > 0 ? (
        <div style={{ height: 500, width: '100%', display: 'flex' }}>
          <ResponsiveBar
            data={chartData}
            keys={['Total']}
            indexBy="date"
            height={500}
            margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
            padding={0.3}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={{ scheme: theme }}
            borderColor={{
              from: 'color',
              modifiers: [['darker', 1.6]],
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 0,
              tickRotation: -15,
              legendPosition: 'start',
              legendOffset: 2,
              truncateTickAt: 100
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: titleAxis,
              format: value => value !== 0 ? `${value.toFixed(0)}` : null,
              legendPosition: 'middle',
              legendOffset: -40,
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            theme={{
              text: {
                fill: themeApp.mode === 'dark' ? '#ffffff' : '#000000'
              }
            }}
            legends={[
              {
                dataFrom: 'keys',
                anchor: 'top-right',
                direction: 'column',
                justify: false,
                translateX: 100,
                translateY: 0,
                itemsSpacing: 4,
                itemWidth: 100,
                itemHeight: 10,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
            role="application"
            ariaLabel="Nivo bar chart demo"
            barAriaLabel={e => e.formattedValue + e.indexValue + "atendimentos"}
            tooltip={({
              id,
              value,
              indexValue,
              color
            }) => <div style={{
              padding: 12,
              color,
              background: '#222222',
              textAlign: 'center'
            }}>
                <span>{title}</span>
                <br />
                <span>{indexValue}</span>
                <br />
                <strong>
                  {id}: {value} {subtitle && (<small>{subtitle}</small>)}
                </strong>
              </div>}
          />
        </div>
      ) : chartData && chartData.length === 0 ? (
        <div
          className="boxChart"
          style={{ height: 400, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px', justifyContent: 'center' }}
        >
          {/*<img src="/loading/loadinginfinite.gif" height={30} alt="Loading..." />*/}
          Nada encontrado
        </div>) : (
        <div
          className="boxChart"
          style={{ height: 400, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px', justifyContent: 'center' }}
        >
          <img src="/loading/loadinginfinite.gif" height={30} alt="Loading..." />
          Carregando dados...
        </div>
      )}
    </>
  );
};

export default ChartDailyTicketsComponent;